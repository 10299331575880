import request from '@/utils/request'


// 查询学习计划列表
export function listStudies(query) {
  return request({
    url: '/studies/studies/list',
    method: 'get',
    params: query
  })
}

// 查询学习计划分页
export function pageStudies(query) {
  return request({
    url: '/studies/studies/page',
    method: 'get',
    params: query
  })
}

// 查询学习计划详细
export function getStudies(data) {
  return request({
    url: '/studies/studies/detail',
    method: 'get',
    params: data
  })
}

// 新增学习计划
export function addStudies(data) {
  return request({
    url: '/studies/studies/add',
    method: 'post',
    data: data
  })
}

// 修改学习计划
export function updateStudies(data) {
  return request({
    url: '/studies/studies/edit',
    method: 'post',
    data: data
  })
}

// 删除学习计划
export function delStudies(data) {
  return request({
    url: '/studies/studies/delete',
    method: 'post',
    data: data
  })
}
